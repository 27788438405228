
























































import { Vue, Component, Watch } from 'vue-property-decorator';
import { PageBase } from '@/core/models/shared';
import { ClienteService } from '@/core/services/geral';
import { Equipamento } from '@/core/models/residuo';
import { EquipamentoService, ResiduoService } from '@/core/services/residuo';

@Component
export default class ConfiguracaoRelatorioLocacao extends PageBase {

    clienteService: ClienteService = new ClienteService();
    onSearchCliente: any = null;
    clientes: any[] = [];
    isClienteLoading: boolean = false;
    embarcacoes: any[] = [];

    residuoService: ResiduoService = new ResiduoService();
    onSearchResiduo: any = null;
    residuos: Equipamento[] = [];

    filtro: any = {
        numOS: null,
        numOD: null,
        clienteId: null,
        embarcacaoId: null,
        mr1: null,
        equipamentoId: null,
        dataInicio: null,
        dataFim: null
    }

    @Watch('onSearchCliente')
    WatchSearchCliente(val: string){
        if(val.length > 0){
            this.isClienteLoading = true;
            this.clienteService.AutoComplete(val).then(
                res => {
                    this.clientes = res.data;
                }
            ).finally(() => {
                this.isClienteLoading = false;
            })
        }
        else
            return;
    }

    @Watch('onSearchResiduo')
    WatchResiduo(val: string){
        if(val.length > 0){
            this.residuoService.AutoComplete(val).then(
                res => {
                    this.residuos = res.data;
                }
            )
        }
        else
            return;
    }

    @Watch('filtro.clienteId')
    WatchCliente(){
        if(this.filtro.clienteId > 0){
            this.clienteService.ObterPorId(this.filtro.clienteId, "Embarcacoes").then(
                res => {
                    this.embarcacoes = res.data.embarcacoes
                }
            )
        }
        else
            return;
    }

    GerarRelatorio(){
        
        let routeLink = this.$router.resolve({name: "detalharRelatorioResiduoDestinacao",
            query:{
                numOS: this.filtro.numOS,
                numOD: this.filtro.numOD,
                clienteId: this.filtro.clienteId,
                embarcacaoId: this.filtro.embarcacaoId,
                mr1: this.filtro.mr1,
                residuoId: this.filtro.residuoId,
                dataInicio: this.filtro.dataInicio,
                dataFim: this.filtro.dataFim
            }
        });
        window.open(routeLink.href, '_blank');
    }

    LimparFiltros(){
        this.clientes = [];
        this.embarcacoes = [];
        this.onSearchCliente = null;
        this.filtro = {
            numOS: null,
            numOD: null,
            clienteId: null,
            embarcacaoId: null,
            mr1: null,
            residuoId: null,
            dataInicio: null,
            dataFim: null
        }
    }
}  

